<template>
  <BasePageSection
    :bg-image="heroBgImg"
    class="home-hero"
    has-overlay
    py-5
    full-bleed-bg
  >
    <VLayout v-if="theLocation" row wrap>
      <VFlex class="text-xs-left">
        <VCard
          :class="['hero-cta elevation-0 py-4', `px-${heroPaddingAmount}`]"
        >
          <VCardText class="pb-5">
            <p class="hero-text white--text mb-6"
              >{{ getContactDetail('street_1') }}<br />
              {{ `${getContactDetail('city')}, ${getContactDetail('state')}`
              }}<br />{{ theLocation.contactinfo.phone_number }}</p
            >
            <p
              v-if="theLocation.hours_for_humans.length"
              class="hero-hours white--text"
              >{{ theLocation.hours_for_humans }}</p
            >
          </VCardText>
          <!-- TODO (ESS) : Make this single loc dynamic -->
          <BaseButtonOutline
            href="https://www.opentable.com/restaurant/profile/1081921/reserve?restref=1081921&lang=en-US&ot_source=Restaurant%20website"
            class="mr-2"
            :large="!['xs', 'sm', 'md'].includes($mq)"
            :block="['xs', 'sm'].includes($mq)"
            color="#0097ce"
          >
            Book A Table
          </BaseButtonOutline>
          <BaseButton
            class="mr-2"
            to="/locations/treps"
            :large="!['xs', 'sm', 'md'].includes($mq)"
            color="white"
            :block="['xs', 'sm'].includes($mq)"
            text-color="#2c365c"
          >
            View Menu
          </BaseButton>
          <BaseButton
            href="https://www.toasttab.com/trepsnola/giftcards"
            :large="!['xs', 'sm', 'md'].includes($mq)"
            color="white"
            text-color="#2c365c"
            :block="['xs', 'sm'].includes($mq)"
          >
            Gift Cards
          </BaseButton>
        </VCard>
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
import { mapGetters } from 'vuex'
import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'TrepsHomeHero',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle']),
    ...mapGetters('locations', ['getLocationBySlug']),
    ...mapGetters('pages', ['getPageContent']),
    heroPaddingAmount() {
      switch (this.$mq) {
        case 'xs':
          return '0'
        case 'sm':
          return '2'
        case 'md':
          return '3'
        default:
          return '5'
      }
    }, // heroPaddingAmount
    theLocation() {
      // TODO (ESS) : make this single loc slug dynamic
      return this.getLocationBySlug('treps')
    }, // theLocation
    heroBgImg() {
      return (
        this.getPageContent('hero_bg_img') ||
        'https://res.cloudinary.com/resto/image/upload/b_rgb:1A1D48,c_fill,e_sharpen:200,e_improve,o_60,w_1600,h_800,g_north/v1600204685/users/trepsnola/shrimp_gumbo_etouffe.jpg'
      )
    },
  },
  methods: {
    formatPhone,
    getContactDetail(key) {
      return getContactDetail(this.theLocation, key)
    }, // getContactDetail
  },
}
</script>

<style lang="scss">
@import '@design';

.home-hero {
  position: relative;
}

.v-card.hero-cta {
  background-color: transparent;
}

.hero-hours {
  white-space: pre-wrap;
}
</style>
